import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/sections/Hero'

export default ({data}) => {
    const {page} = data
    const {contact} = data

    const iframe = () => {
        return (
            `<iframe class="mb-0 block" src="https://maps.google.com/maps?q=${page.lat},${page.long}&hl=gb&z=14&output=embed" width="100%" height="600" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
        )
    }
    const openingHours = () => {
        return contact.openingHours.map((opening, i) => <span className="block mb-2" key={i}>{opening}</span>)
    }
    return (
        <Layout>
            <SEO 
              title={page.seoInfo.title}
              description={page.seoInfo.metaDescription.metaDescription}
              robots={page.seoInfo.metaRobots}
              ogDescription={page.seoInfo.openGraphDescription}
              twitterDescription={page.seoInfo.twitterCardDescription}
              twitterTitle={page.seoInfo.twitterCardTitle}
              twitterImage={page.seoInfo.twitterCardImage}
              ogTitle={page.seoInfo.openGraphTitle}
              twitterImage={page.seoInfo.twitterImage}
              ogImage={page.seoInfo.openGraphImage.localFile.url}
            />
            <Hero image={page.backgroundImage.fluid} title={page.bannerTitle} height="medium"/>
            <div className="container mx-auto">
                <div className="px-4">
                    <div className="shadow-2xl md:-mt-24 relative mb-16 md:mb-24" dangerouslySetInnerHTML={{__html: iframe() }} />
                </div>
                <div className="flex flex-wrap mb-16 md:mb-24">
                    <div className="w-full md:w-2/5 px-4">
                        <div className="border-b border-solid border-gray-200 border-t-0 border-l-0 border-r-0 mb-10 pb-10">
                            {page.detailsTitle && <h2 className="mb-4 uppercase font-extrabold">{page.detailsTitle}</h2>}
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-1/2 mb-5">
                                    {contact.address && <p className="mb-0" dangerouslySetInnerHTML={{__html: contact.address }} />}
                                </div>
                                <div className="w-full lg:w-1/2 mb-5">
                                    {contact.openingHours && openingHours()}
                                </div>
                            </div>
                        </div>
                        {page.contactTitle && <h2 className="mb-4 uppercase font-extrabold">{page.contactTitle}</h2>}
                        <div className="flex flex-wrap">
                          <div className="w-full mb-5">
                              {contact.phone && <div><b>Tel:</b> <a href={`tel:${contact.phone}`}>{contact.phone}</a></div>}
                          </div>
                          <div className="w-full">
                              {contact.email && <div><b>Email:</b> <a href={`mailto:${contact.email}`}>{contact.email}</a></div>}
                          </div>
                        </div>
                    </div>
                    <div className="w-full md:w-3/5 px-4">
                        <form name="Contact Form" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                            <input type="hidden" name="form-name" value="Contact Form" />
                            <p>
                                <label>Full Name: <input name="name" placeholder="E.G John Smith…" className="w-full p-4 mb-2 border-gray-200 border-solid" type="text" required /></label>   
                            </p>
                            <p>
                                <label>Email: <input name="email" placeholder="E.G Johnsmith@gmail…" className="w-full p-4 mb-2 border-gray-200 border-solid" type="email" required /></label>
                            </p>
                            <p>
                                <label>Phone: <input name="phone" placeholder="E.G 01234 556 677" className="w-full p-4 mb-2 border-gray-200 border-solid" type="number" /></label>
                            </p>
                            <p>
                                <label>Company: <input name="company" placeholder="E.G Facebook" className="w-full p-4 mb-2 border-gray-200 border-solid" type="text" /></label>
                            </p>
                            <p>
                                <label>Message: <textarea name="message" required placeholder="Message…" className="w-full p-4 mb-2 border-gray-200" rows="5"></textarea></label>
                            </p>
                            <p>
                                <button type="submit" className="btn cursor-pointer transition bg-primary border-0 text-white px-16 py-4 shadow-lg rounded font-bold block">Submit Message</button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql `
query($id: String!) {
    page: contentfulContact(id: {eq: $id}) {
        title
        lat 
        long
        bannerTitle
        backgroundImage {
            fluid(maxWidth: 1920, quality: 70) {
                ...GatsbyContentfulFluid
            }
        }
        detailsTitle
        contactTitle
        seoInfo {
          canonicalUrl
          metaDescription {
            metaDescription
          }
          metaRobots
          openGraphDescription
          openGraphTitle
          title
          twitterCardImage
          twitterCardDescription
          twitterCardTitle
          openGraphImage {
            localFile {
              url
            }
          }
        }
    }
    contact: contentfulSettings(id: {eq: "c73f9c34-0844-52cd-828a-c8538330c4b5"}) {
        address
        email
        phone
        openingHours
    }
}
`
